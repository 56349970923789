const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'pickupSelect.config.innoshipBox.pickupInstructionsShippingText1' },
      {
        text: 'pickupSelect.config.innoshipBox.pickupInstructionsShippingText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'pickupSelect.config.innoshipBox.pickupInstructionsSmsText1',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'pickupSelect.config.innoshipBox.pickupInstructionsCalendarText1' },
      {
        text: 'pickupSelect.config.innoshipBox.pickupInstructionsCalendarText2',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'pickupSelect.config.innoshipBox.orderLabel',
  unavailablePickupLabel: 'pickupSelect.config.innoshipBox.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.innoshipBox.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.innoshipBox.confirmButtonLabel',

  getTitle() {
    return [this.title, this.location.street].filter(Boolean).join(', ')
  },

  getSubtitle() {
    return [this.location.zipCode, this.location.city].filter(Boolean).join(' ')
  },

  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-boxes-enabled.svg',
    disabled: 'pin-boxes-disabled.svg',
    selected: 'pin-boxes-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#BA1B02',
  icon: '/img/delivery/box.svg',
}
