const pickupInstructions = [
  {
    icon: 'sms',
    fragments: [{ text: 'pickupSelect.config.drmaxBox.pickupInstructionsSmsText1' }],
  },
  {
    icon: 'pharmacy',
    fragments: [{ text: 'pickupSelect.config.drmaxBox.pickupInstructionsPharmacyText1' }],
  },
]

export default {
  orderLabel: 'pickupSelect.config.drmaxBox.orderLabel',
  unavailablePickupLabel: 'pickupSelect.config.drmaxBox.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.drmaxBox.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.drmaxBox.confirmButtonLabel',
  getTitle() {
    return [this.title, this.location.street].filter(Boolean).join(', ')
  },

  getSubtitle() {
    return [this.location.zipCode, this.location.city].filter(Boolean).join(' ')
  },

  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  getReviewAddress() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  pins: {
    enabled: 'pin-boxes-enabled.svg',
    disabled: 'pin-boxes-disabled.svg',
    selected: 'pin-boxes-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/box.svg',
}
