const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'pickupSelect.config.packeta.pickupInstructionsShippingText1' },
      {
        text: 'pickupSelect.config.packeta.pickupInstructionsShippingText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'pickupSelect.config.packeta.pickupInstructionsSmsText1',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'pickupSelect.config.packeta.pickupInstructionsCalendarText1' },
      {
        text: 'pickupSelect.config.packeta.pickupInstructionsCalendarText2',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'pickupSelect.config.packeta.orderLabel',
  unavailablePickupLabel: 'pickupSelect.config.packeta.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.packeta.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.packeta.confirmButtonLabel',

  getTitle() {
    return [this.title, this.location.street].filter(Boolean).join(', ')
  },

  getSubtitle() {
    return [this.location.zipCode, this.location.city].filter(Boolean).join(' ')
  },

  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  getReviewAddress() {
    return this.location.street
  },
  pins: {
    enabled: 'pin-packeta-enabled.svg',
    disabled: 'pin-packeta-disabled.svg',
    selected: 'pin-packeta-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#BA1B02',
  icon: '/img/delivery/packeta.svg',
}
