import { defuFn } from 'defu'
import { default as rootConfig } from 'drmax/config/sk/app.config.mjs'
import { default as i18n } from '@nsf/i18n/config/sk/app.config.mjs'
import { default as base } from '@nsf/base/config/sk/app.config.mjs'
import { default as algolia } from '@nsf/algolia/config/sk/app.config.mjs'
import { default as search } from '@nsf/search/config/sk/app.config.mjs'
import { default as articles } from '@nsf/articles/config/sk/app.config.mjs'
import { default as apothekaWeb } from '@nsf/apotheka-web/config/sk/app.config.mjs'
import { default as pickupSelectV2 } from '@nsf/pickup-select-v2/config/sk/app.config.mjs'
import { default as checkout } from '@nsf/checkout/config/sk/app.config.mjs'
import { default as catalog } from '@nsf/catalog/config/sk/app.config.mjs'
import { default as gtm } from '@nsf/gtm/config/sk/app.config.mjs'
import { default as deliveryFe } from '@nsf/delivery-fe/config/sk/app.config.mjs'
import { default as cms } from '@nsf/cms/config/sk/app.config.mjs'

const inlineConfig = {}
export default /* #__PURE__ */ defuFn({rootConfig, i18n, base, algolia, search, articles, apothekaWeb, pickupSelectV2, checkout, catalog, gtm, deliveryFe, cms}, inlineConfig)
