import { defuFn } from 'defu'
import { default as rootConfig } from 'drmax/runtime.config.mjs'
import { default as base } from '@nsf/base/nuxt.config.mjs'
import { default as algolia } from '@nsf/algolia/nuxt.config.mjs'
import { default as search } from '@nsf/search/nuxt.config.mjs'
import { default as pickupSelectV2 } from '@nsf/pickup-select-v2/nuxt.config.mjs'
import { default as apothekaWidget } from '@nsf/apotheka-widget/nuxt.config.mjs'
import { default as checkout } from '@nsf/checkout/nuxt.config.mjs'
import { default as catalog } from '@nsf/catalog/nuxt.config.mjs'
import { default as gtm } from '@nsf/gtm/nuxt.config.mjs'
import { default as productPreview } from '@nsf/product-preview/nuxt.config.mjs'
import { default as cms } from '@nsf/cms/nuxt.config.mjs'

const inlineConfig = {}
export default /* #__PURE__ */ defuFn(rootConfig, base, algolia, search, pickupSelectV2, apothekaWidget, checkout, catalog, gtm, productPreview, cms, inlineConfig)
